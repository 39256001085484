import React from 'react'
import { navigate } from '@reach/router'
// import { useDispatch } from 'react-redux'
// import { setOpenPopupSignUp } from '../../actions/common'

const SectionBoxReady = ({ marginBottom, marginTop }) => {
     // const dispatch = useDispatch()

     const onClickGetStarted = () => {
          navigate(`${process.env.GATSBY_APP_PATH}/register/?navigate=true`)
          // window.location.replace(`${process.env.GATSBY_APP_PATH}/register`)
     }

     return (
          <section>
               <div className={`section-ready ${marginBottom && marginBottom} ${marginTop && marginTop}`}>
                    <div className="container">
                         <div className=" section-ready-box">
                              <div className="title">
                                   <h3>Ready to Dive In?</h3>
                                   <p>See how easy (and powerful) ListenLayer is by opening an account today or requesting a demo.</p>
                              </div>
                              <div className="button-wrapper">
                                   <button onClick={() => onClickGetStarted()}>Get started</button>
                              </div>
                         </div>
                    </div>
               </div>
          </section>
     )
}

export default SectionBoxReady
