import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import { useCalendlyEventListener, InlineWidget } from 'react-calendly'

const PopupSchedule = ({ showSchedule, setShowSchedule }) => {
     // const [showSchedule, setShowSchedule] = useState(false)
     // const handleShowSchedule = () => setShowSchedule(true)

     const [isLoading, setIsLoading] = useState(true)
     const [isSuccess, setIsSuccess] = useState(false)

     const updateUrl = () => {
          if (window.location.href.includes('?view-demo=1&')) {
               let newURL = window.location.href.replace('?view-demo=1&', '?');
               window.history.replaceState(null, null, newURL);
          } else {
               let updatedURL = window.location.href.replace(/(\?|\&)view-demo=1/g, '');
               window.history.replaceState(null, null, updatedURL);
          }
     }

     const handleCloseSchedule = () => {
          setShowSchedule(false);
          setIsSuccess(false);
          updateUrl();
     }

     const clickThankSchedule = () => {
          setShowSchedule(false);
          setIsSuccess(false);
          updateUrl();
     }

     useCalendlyEventListener({
          onEventTypeViewed: () => setIsLoading(false),
          onEventScheduled: e => {
               if (e.data.event) {
                    // toast.success('Schedule a Talk to Sales Successful')
                    setIsSuccess(true)
               }
          },
     })

     return (
          <>
               <Modal className="scheduleDemo" show={showSchedule} onHide={handleCloseSchedule} animation={false} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                         {isSuccess ? (
                              <div className="section-thankyou">
                                   <h3>Thank you!</h3>
                                   <p>
                                        Your meeting has been schedule. Check you email, an invitation was sent with details and will be added to your
                                        canlendar
                                   </p>
                                   <div className="button-wrapper">
                                        <button onClick={clickThankSchedule}>Go back</button>
                                   </div>
                              </div>
                         ) : (
                              <div className="section-schedule">
                                   <h3>Schedule a Demo</h3>
                                   <p className="des">
                                        Grab a time below for an initial 15-minute discovery call. Then we'll schedule a custom demo specifically for
                                        you.
                                   </p>
                                   {isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
                                   <InlineWidget
                                        url="https://calendly.com/listenlayer-rick/schedule-a-demo"
                                        styles={{
                                             height: '1000px',
                                        }}
                                   />
                              </div>
                         )}
                    </Modal.Body>
               </Modal>
          </>
     )
}

export default PopupSchedule;