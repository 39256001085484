import React, { useEffect, useState } from 'react'

import SEO from '../../components/seo'
import Layout from '../../components/layout'
import HeaderIntroBlock from '../../components/Blocks/HeaderIntroBlock'
import HowItWorkBlock from '../../components/Blocks/HowItWorkBlock'
import SectionBoxReady from '../../components/Platfrom/SectionBoxReady'
import ImageTextBlock from '../../components/Blocks/ImageTextBlock'
import BreadcrumbSection from '../../components/breadcrumb'
import PopupSchedule from '../../components/popupSchedule/PopupSchedule'

const ExternalData = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const pageName = 'External Data'
     const breadcrumbItems = [
          {
               name: 'Platform',
               url: `${'platform'}`,
          },
          {
               name: `${pageName}`,
          },
     ]
     const headerIntroContent = {
          // subtitle: 'subtitle demo',
          title: 'Connect Marketing Analytics to Your External Data',
          // description: 'No more anonmyous data that leaves you wondering "what\'s really working?"...',
          substring: 'No more isolated, anonymous marketing data that belongs to Google and Facebook.',
     }
     const headerIntroCard = {
          description: `<span>Your business operates on advertising, CRM, and revenue data from other sources. Connect them to ListenLayer to understand your target audiences and gain insights you never thought possible.</span>`,
          fName: 'Zachary Randall',
          fPosition: 'Founder & CEO, ListenLayer',
          imageURL: '/images/hero-image.png',
          imageAlt: 'Zachary Randall',
     }
     const headerIntroStep = [
          {
               step: 'Step 1:',
               title: 'Centralize Your Strategy',
               description:
                    "Use ListenLayer to define and centralize a single marketing data strategy that's planned, accurate, documented, and distributed. ",
          },
          {
               step: 'Step 2:',
               title: 'Connect Data',
               description:
                    'Connected external data sources such as Salesforce CRM, advertising spend and more. Build a data relationship model custom to your situation.',
          },
          {
               step: 'Step 3:',
               title: 'Learn & Analyze',
               description: "Use ListenLayer's Insights engine to understand behavior, audiences, and attribution.",
          },
     ]
     const firstContent = {
          headline: '',
          body:
               "<p>For years, marketers have been subjected to \"anonymous\" data that is separated from the truth, and oftentimes splity across multiple platforms.</p>\n<p>But this conflicts with the marketer's mandate: to prove what works, and optimize towards revenue-based outcomes. </p>\n <p>ListenLayer allows you to use your data the way you've always wanted. How can we do this, while others cannot? It's simple: </p>\n",
     }
     const actions = [
          {
               detail: "We don't own and use your data for our own benefit. As a results, we aren't at risk of misapropriating it.",
               iconSvg: '',
               title: "It's Your Data",
          },
          {
               detail:
                    "We take the security of your data seriously, and we provide powerful compliance and privacy tools that aren't available in other analytics platforms. ",
               iconSvg: '',
               title: 'Compliance & Security',
          },
          {
               detail: "We've architected our data model to be extensible, allowing yout coonnect and map relationship with external data sources.",
               iconSvg: '',
               title: 'Extensible Data',
          },
     ]
     const secondContent = {
          // headline: 'Gain insights into <span class="underline">how marketing activity</span> generates <span class="underline">real customers and revenue</span>',
          // body: '<p>Connect external data and use ListenLayer&#8217;s analytics to understand how marketing activities and website content influences leads, pipeline, purchases, and revenue.</p>\n',
     }
     const dataImageTextBlock = [
          {
               // iconSvg: '/images/Shape4x.png',
               title: "Don't Do This Alone",
               description:
                    'We offer implementation and managed services to help you succeed, or rely on our automated onboarding and world-class support.',
          },
     ]

     const [showSchedule, setShowSchedule] = useState(false)

     useEffect(() => {
          if (showSchedule) {
               document.querySelector('html').setAttribute('style', 'overflow:hidden')
          } else {
               document.querySelector('html').setAttribute('style', 'overflow:initial')
          }
     }, [showSchedule])

     useEffect(() => {
          if (window.location.href.includes('view-demo=1')) {
               setShowSchedule(true)
          }
     }, [])

     return (
          <Layout>
               <SEO canonical={canonical} />
               <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems} />
               <HeaderIntroBlock headerIntroContent={headerIntroContent} headerIntroCard={headerIntroCard} headerIntroStep={headerIntroStep} />
               <section className="enhance-how-work monitor-how-work">
                    <div className="container">
                         <HowItWorkBlock firstContent={firstContent} actions={actions} secondContent={secondContent} />
                    </div>
               </section>
               <SectionBoxReady />
               <section className="enhance-imgtext-block enhance-img-text">
                    <div className="container">
                         <ImageTextBlock dataImageTextBlock={dataImageTextBlock} />
                    </div>
               </section>
          </Layout>
     )
}

export default ExternalData
