import React from 'react'

const ImageTextBlock = ({ dataImageTextBlock }) => {
     // console.log('dataImageTextBlock', dataImageTextBlock)

     return (
          <>
               {dataImageTextBlock &&
                    dataImageTextBlock.map((dataImageTextBlock, index) => {
                         return (
                              <div className="imgtext-block" key={index}>
                                   {dataImageTextBlock.iconSvg && (
                                        <div className="imgtext-block-left">
                                             {/* {dataImageTextBlock.iconSvg} */}
                                             {/* <div className="icon" dangerouslySetInnerHTML={{ __html: dataImageTextBlock.iconSvg }}></div> */}
                                             <img src={`${dataImageTextBlock.iconSvg}`} />
                                        </div>
                                   )}
                                   <div className={`imgtext-block-right ${!dataImageTextBlock.iconSvg && 'imgtext-block-noimg'}`}>
                                        {dataImageTextBlock.title && <h3 dangerouslySetInnerHTML={{ __html: dataImageTextBlock.title }}></h3>}

                                        {dataImageTextBlock.description && <p>{dataImageTextBlock.description}</p>}

                                        {dataImageTextBlock.otherDesc && <p className='mt-2'>{dataImageTextBlock.otherDesc}</p>}
                                   </div>
                              </div>
                         )
                    })}
          </>
     )
}

export default ImageTextBlock
