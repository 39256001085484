import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb'

const BreadcrumbSection = ({ breadcrumbItems }) => {
    return (
        <section className="breadcrumb-custom">
            <div className="container">
                <Breadcrumb>
                    <Breadcrumb.Item href="/" target="_self">Home</Breadcrumb.Item>
                    {
                        breadcrumbItems.map((breadcrumbItem, index) => {
                            if (index + 1 === breadcrumbItems.length) {
                                return (
                                    <Breadcrumb.Item key={index} active>{breadcrumbItem.name}</Breadcrumb.Item>
                                )
                            }
                            return (
                                <Breadcrumb.Item key={index} href={`/${breadcrumbItem.url}/`} target="_self" >{breadcrumbItem.name}</Breadcrumb.Item>
                            )
                        })
                    }

                </Breadcrumb>
            </div>
        </section>
    )
}

export default BreadcrumbSection
