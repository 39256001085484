import React from 'react'

const HeaderIntroBlock = ({ headerIntroContent, headerIntroCard, headerIntroStep }) => {
     return (
          <section className="header-intro">
               {/* <div className="circle-green"></div>
               <div className="circle-blue"></div>
               <div className="circle-yellow"></div> */}
               <div className="container">
                    <div className="wrapper-header-intro">
                         <div className="header-intro-text">
                              {headerIntroContent.subtitle && <p className="header-intro-subtitle">{headerIntroContent.subtitle}</p>}
                              {headerIntroContent.title && <h1 className="header-intro-title">{headerIntroContent.title}</h1>}
                              {headerIntroContent.description && <p className="header-intro-str">{headerIntroContent.description}</p>}
                              {headerIntroContent.substring && <p className="header-intro-substr">{headerIntroContent.substring}</p>}
                         </div>
                         {headerIntroStep && headerIntroStep?.length > 0 && (
                              <div className="box-card-step">
                                   <div className="row">
                                        {headerIntroStep.map((headerIntroStep, index) => {
                                             return (
                                                  <div className="col-md-12 col-lg-4 mb-4">
                                                       <div className="card-step">
                                                            <div className="card-body">
                                                                 <h3>{headerIntroStep.step}</h3>
                                                                 <p className="card-text">{headerIntroStep.title}</p>
                                                                 <p className="card-desc">{headerIntroStep.description}</p>
                                                            </div>
                                                       </div>
                                                  </div>
                                             )
                                        })}
                                   </div>
                              </div>
                         )}

                         <div className="header-intro-card">
                              <div className="circle-green"></div>
                              <div className="circle-blue"></div>
                              <div className="circle-yellow"></div>
                              <div className="header-intro-left">
                                   {headerIntroCard.description && (
                                        <div
                                             className="header-intro-left-desc"
                                             dangerouslySetInnerHTML={{ __html: headerIntroCard.description }}
                                        ></div>
                                   )}

                                   <div className="header-intro-left-info">
                                        {headerIntroCard.fName && <span className="header-intro-name">{headerIntroCard.fName}</span>}

                                        {headerIntroCard.fPosition && <span className="header-intro-position">{headerIntroCard.fPosition}</span>}
                                   </div>
                              </div>
                              <div className="header-intro-right">
                                   {headerIntroCard.imageURL && <img className="" alt="Zachary W. Randall" src={`${headerIntroCard.imageURL}`}></img>}
                              </div>
                         </div>
                    </div>
               </div>
          </section>
     )
}

export default HeaderIntroBlock
